import { Button, Col, Form, Row } from "react-bootstrap";
import CardBasic from "../Card/card.basic";
import { TfiBlackboard } from "react-icons/tfi";
import { RiSecurePaymentFill, RiShoppingCart2Line } from "react-icons/ri";
import { useEffect, useState } from "react";
import SideModal from "../SideModal/SideModal";
import CalenderInput from "../Modals/DatePicker";
import Select from "react-select";
import { AdminService } from "../../services/admin.service";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import AsyncSelect from "react-select/async";
import { useAppDispatch, useAppSelector } from "../../store/reduxHooks";
import { ArrayHelper } from "../../helpers/array.helper";
import { onFetchInstructorCourse } from "../../store/actions/InstructorCourse";
import { IcreateEnrollment } from "../../interfaces/createEnrollment";
import moment from "moment";
// import { FaDollarSign } from "react-icons/fa6";
import { AiOutlineDollar } from "react-icons/ai";
import StudentService from "../../services/student.service";
import { freshworksInstrument } from "../../helpers/subject.helper";
import { dealsType, getStageOptionsByPipeline } from "../../helpers/freshworks.helper";

interface IControlbarStudent {
  studentId: string;
}

interface DealsData {
  name: string;
  id: string;
  amount: number;
  status: string;
}

interface NewDealsData {
  name: string;
  id: string;
  amount: number;
  status: string;
  type: string;
  phone: string,
  dhunguruId: string,
  subject: string,
  teacher: string,
  owner: string,
  pipeline: string,
  dealStage: string,
}

export default function ControlbarStudent({ studentId }: IControlbarStudent) {
  const [showSessionModal, setShowSessionModal] = useState<boolean>(false);
  const [showEnrollmentModal, setShowEnrollmentModal] =
    useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(moment().add("1", "hours").startOf("hour").toDate());
  const [errors, setErrors] = useState(Array(3).fill(false));
  const [selectValue, setSelectValue] = useState<any>();
  const [enrollment, setEnrollment] = useState<any>({});
  const [showDealModal, setShowDealModal] = useState(false);
  const [showNewDealModal, setShowNewDealModal] = useState(false);
  const [dealsData, setDealsData] = useState<DealsData>({
    name: '',
    id: '',
    amount: 0,
    status: '',
  });

  const [newDealsData, setNewDealsData] = useState<NewDealsData>({
    name: '',
    id: '',
    amount: 0,
    status: '',
    type: '',
    phone: '',
    dhunguruId: '',
    subject: '',
    teacher: '',
    owner: '',
    pipeline: '',
    dealStage: '',
  });
  const [FreshWorksId, setFreshWorksId] = useState('');
  const [studentDetails, setStudentDetails] = useState<any>('');

  // const [inputStudentValue, setInputStudentValue] = useState<any>();
  const [inputCourseValue, setInputCourseValue] = useState<any>();
  const [finalSaveState, setFinalSaveState] = useState<any>({
    coordinator: "",
    totalSession: 0,
    amountPaid: 0,
    dhunguruDiscount: 0
  });
  const [totalSessions, setTotalSessions] = useState<any>();
  const [pricePerClass, setPricePerClass] = useState<number>(0);

  const [instructorCourses, setInstructorCourses] = useState<any>({});
  const [selOption, setSelOption] = useState<any>({});
  const [pipelineStageOptions, setPipelineStageOptions] = useState<any>();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const handelInstructorChanges = (obj: any) => {
    setSelOption(obj);
  };

  const paymentStatus = [
    {
      value: 'Pending',
      label: 'Pending',
    },
    {
      value: 'Paid',
      label: 'Paid',
    },
    {
      value: 'Partial',
      label: 'Partial'
    }
  ]

  const id = selOption?.value || "";
  const courseDetail = useAppSelector(
    (state) => state.instructorCourses.courseDetail
  );

  const totalSession1 = [
    {
      num_classes: "1",
      discount: "0",
    },
    {
      num_classes: "2",
      discount: "0",
    },
    {
      num_classes: "3",
      discount: "0",
    },
    {
      num_classes: "4",
      discount: "0",
    },
    {
      num_classes: "5",
      discount: "0",
    },
    {
      num_classes: "6",
      discount: "0",
    },
    {
      num_classes: "7",
      discount: "0",
    },
  ];

  const totalSession2 = [
    {
      num_classes: "48",
      discount: "40",
    },
    {
      num_classes: "96",
      discount: "40",
    },
  ];

  useEffect(() => {
    if (inputCourseValue) {
      const current_course = courseDetail?.find(
        (course: any) => course?._id === inputCourseValue?.value
      );
      const newTotalSessions = totalSession1.concat(
        current_course?.discount,
        totalSession2
      );
      const uniqueElements = ArrayHelper.uniqueObjects(newTotalSessions);
      setPricePerClass(current_course?.price_per_class);
      setTotalSessions(
        uniqueElements?.map((data: any) => {
          return {
            value: Number(data?.num_classes),
            label:
              "Buy " +
              data?.num_classes +
              " classes and  get " +
              data?.discount +
              " % off",
          };
        })
      );
    }
  }, [inputCourseValue]);

  useEffect(() => {
    if (!id) return;
    dispatch(onFetchInstructorCourse({ id }));
  }, [id, dispatch]);

  const fetchInstructorData = (inputValue: any, callback: ([]) => void) => {
    if (!inputValue) {
      callback([]);
    } else {
      setTimeout(() => {
        AdminService.getAllInstructor(inputValue).then((res) => {
          if (res.status === 200) {
            const instructors = res.data.data.map((data: any) => {
              return {
                value: data._id,
                label: `${data.firstName}  ${data.lastName} - ${data.email}`,
              };
            });
            callback(instructors);
          }
        });
      }, 1000);
    }
  };

  const fetchCourseData = () => {
    if (!id) return;
    AdminService.getInstructorCourses({ id }).then((res) => {
      if (res.status === 200) {
        setInstructorCourses(
          res?.data?.data?.map((data: any) => {
            return {
              value: data?._id,
              label: `${data?.title}`,
            };
          })
        );
      }
    });
  };

  useEffect(() => {
    fetchCourseData();
  }, [id]);

  const fetchStudentData = (inputValue: any, callback: ([]) => void) => {
    if (!inputValue) {
      callback([]);
    } else {
      setTimeout(() => {
        AdminService.getAllStudents(inputValue).then((res) => {
          if (res.status === 200) {
            const students = res.data.data.map((data: any) => {
              return {
                value: data._id,
                label: `${data.firstName}  ${data.lastName} - ${data.email}`,
              };
            });
            callback(students);
          }
        });
      }, 1000);
    }
  };

  const handleChange = (e: any) => {
    setFinalSaveState({ ...finalSaveState, [e.target.name]: e.target.value });
  };

  const handleChangeDeals = (e: any, type: any, selectType?: any) => {
    if (type == 'input') {
      setDealsData({ ...dealsData, [e.target.name]: e.target.value });
    }
    else if (type == 'select') {
      if (selectType == 'status') {
        setDealsData({ ...dealsData, ['status']: e.value });
      }
      else if (selectType == 'dealType') {
        if (dealsData.name == null || dealsData.name.length < 1) {
          toast.error('First enter deal name');
          return;
        }
        if (e.value == dealsType.AS) {
          const dealsName = dealsData.name;
          const currentName = dealsName.replace(/^(AS|NS|RF|RW) - /, '');
          const newDealsName = "AS - " + currentName;
          setDealsData({ ...dealsData, name: newDealsName });
        }
        else if (e.value == dealsType.NS) {
          const dealsName = dealsData.name;
          const currentName = dealsName.replace(/^(AS|NS|RF|RW) - /, '');
          const newDealsName = "NS - " + currentName;
          setDealsData({ ...dealsData, name: newDealsName });
        }
        else if (e.value == dealsType.RF) {
          const dealsName = dealsData.name;
          const currentName = dealsName.replace(/^(AS|NS|RF|RW) - /, '');
          const newDealsName = "RF - " + currentName;
          setDealsData({ ...dealsData, name: newDealsName });
        }
        else if (e.value == dealsType.RW) {
          const dealsName = dealsData.name;
          const currentName = dealsName.replace(/^(AS|NS|RF|RW) - /, '');
          const newDealsName = "RW - " + currentName;
          setDealsData({ ...dealsData, name: newDealsName });
        }
        else {
          toast.error('Enter valid value');
        }
      }
    }
    return;
  };

  const addDeal = async () => {
    console.log(dealsData)
    setLoading(true);
    await AdminService.addFreshworksDeals(studentId, dealsData).then((res) => {
      if (res.status == 200) {
        toast.success('Deal added');
        setShowDealModal(false);
        setLoading(false);
      }
      else {
        toast.error('Deal not added');
        setLoading(false);
      }
    })
      .catch((error) => {
        console.log(error);
        toast.error('Deal not added');
        setLoading(false);
      })
  }


  const handleChangeNewDeals = (e: any, type: any, selectType?: any) => {
    if (type == 'input') {
      setNewDealsData({ ...newDealsData, [e.target.name]: e.target.value });
    }
    else if (type == 'select') {
      if (selectType == 'status') {
        setNewDealsData({ ...newDealsData, ['status']: e.value });
      }
      else if (selectType == 'dealType') {
        if (newDealsData.name == null || newDealsData.name.length < 1) {
          toast.error('First select guru');
          return;
        }
        if (e.value == dealsType.AS) {
          const dealsName = newDealsData.name;
          const currentName = dealsName.replace(/^(AS|NS|RF|RW) - /, '');
          const newDealsName = "AS - " + currentName;
          setNewDealsData({ ...newDealsData, type: 'AS', name: newDealsName, pipeline: e.value });
        }
        else if (e.value == dealsType.NS) {
          const dealsName = newDealsData.name;
          const currentName = dealsName.replace(/^(AS|NS|RF|RW) - /, '');
          const newDealsName = "NS - " + currentName;
          setNewDealsData({ ...newDealsData, type: 'NS', name: newDealsName, pipeline: e.value  });
        }
        else if (e.value == dealsType.RF) {
          const dealsName = newDealsData.name;
          const currentName = dealsName.replace(/^(AS|NS|RF|RW) - /, '');
          const newDealsName = "RF - " + currentName;
          setNewDealsData({ ...newDealsData, type: 'RF', name: newDealsName, pipeline: e.value  });
        }
        else if (e.value == dealsType.RW) {
          const dealsName = newDealsData.name;
          const currentName = dealsName.replace(/^(AS|NS|RF|RW) - /, '');
          const newDealsName = "RW - " + currentName;
          setNewDealsData({ ...newDealsData, type: 'RW', name: newDealsName, pipeline: e.value  });
        }
        else {
          toast.error('Enter valid value');
        }
      }
      else if(selectType=='teacher'){
        const teacherName = e.label.split('-')[0];
        const studentName = studentDetails.firstName + " "+ studentDetails.lastName;
        const dealName = teacherName.trim() + "<>"+studentName.trim();
        setNewDealsData({ ...newDealsData, ['teacher']: teacherName, name: dealName });
      }
      else if(selectType=='subject'){
        setNewDealsData({ ...newDealsData, ['subject']: e.value });
      }
      else if(selectType=="pipelineStage"){
        console.log(newDealsData);
        if (newDealsData.pipeline == null || newDealsData.pipeline.length < 1) {
          toast.error('First select pipeline');                                                                                                                                                                                                                                                                                                            
          return;
        }
        setNewDealsData({...newDealsData, dealStage: e.value});
      }
    }
    return;
  };

  const getStudentDetails = async () => {
    return await StudentService.getStudentDetails(studentId).then((res) => {
      if (res.status == 200) {
        console.log(res.data);
        setStudentDetails(res.data?.userData);
      }
    });
  };

  useEffect(()=>{
    getStudentDetails();
  },[])

  const getFreshWorksIdByDhunguruId = async () => {
    await AdminService.getFreshWorksLeadIdByDhunguruId(studentId).then((res) => {
      if (res.status == 200) {
        setFreshWorksId(res.data.id);
      }
    })
      .catch((error) => {
        toast.error("Something went wrong");
        console.log(error);
      })
  }


  const addNewDeal = async () => {
    setLoading(true);
    const updatedDealsData = { ...newDealsData, phone: studentDetails.phone, dhunguruId: studentId, id: FreshWorksId };
    console.log(updatedDealsData)
    await AdminService.createManualDeal(updatedDealsData).then((res) => {
      if (res.status == 200) {
        toast.success('Deal created');
        setNewDealsData({
          name: '',
          id: '',
          amount: 0,
          status: '',
          type: '',
          phone: '',
          dhunguruId: '',
          teacher: '',
          owner: '',
          subject: '',
          pipeline: '',
          dealStage: '',
        })
        setShowDealModal(false);
        setLoading(false);
      }
      else {
        toast.error('Deal not created');
        setLoading(false);
      }
    })
      .catch((error) => {
        console.log(error);
        toast.error('Deal not created');
        setLoading(false);
      })
  }



  const handleSelectChange = (e: any, name: string) => {
    setFinalSaveState({ ...finalSaveState, [name]: e.value });
  };

  const allEnrollments = async () => {
    await AdminService.getStudentEnrollment({ id: studentId })
      .then((res) => {
        if (res.status === 200) {
          const paidEnrollments = res.data.filter(
            (enrollment: any) => enrollment.type == "paid"
          );
          setSelectValue(
            paidEnrollments.map((data: any) => {
              return {
                label:
                  data.instructor.firstName +
                  " " +
                  data.instructor.lastName +
                  " - " +
                  data.enrollmentId +
                  " - " +
                  data?.course?.instrument,
                value: data.enrollmentId,
              };
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createSchedule = async (e: any) => {
    setLoading(true)
    await AdminService.createSession({
      enrollmentId: enrollment.value,
      date: [date.toISOString()],
    })
      .then((res: any) => {
        if (res.status === 200) {
          toast.success("Sessions Booked");
        }
        setEnrollment("");
        setShowSessionModal(false);
      })
      .catch((err: any) => {
        toast.error(err.response.data.message || "Unable to create Session");
      });
    setLoading(false);
  };

  useEffect(() => {
    getFreshWorksIdByDhunguruId();
    allEnrollments();
  }, []);

  const onSubmit = async () => {
    setLoading(true)
    const inputData: IcreateEnrollment = {
      instructorId: selOption?.value || " ",
      studentId: studentId,
      courseId: inputCourseValue?.value || " ",
      totalSession: finalSaveState?.totalSession || 0,
      pricePerClass: pricePerClass || 0,
      instructorDiscount: getInstructorDiscount() || 0,
      coordinator: finalSaveState?.coordinator || " ",
      amountPaid: finalSaveState?.amountPaid,
      dhunguruDiscount: finalSaveState?.dhunguruDiscount
    };

    await AdminService.createManualEnrollment(inputData)
      .then((res: any) => {
        if (res.status === 200) {
          setShowEnrollmentModal(false);
          setFinalSaveState({
            coordinator: "",
            totalSession: 0,
            amountPaid: 0,
            selOption: {},
            inputCourseValue: {},
          });
          toast.success("Success: Enrollment Created!");
        }
      })
      .then((e) => {
        console.error(e);
      });
    setLoading(false)

  };

  const getInstructorDiscount: any = () => {
    if (
      courseDetail &&
      courseDetail.length > 0 &&
      inputCourseValue &&
      inputCourseValue.value
    ) {
      const current_course = courseDetail.find(
        (course: any) => course._id == inputCourseValue.value
      );

      const newTotalSessions = totalSession1.concat(
        current_course?.discount,
        totalSession2
      );

      if (current_course && newTotalSessions) {
        const current_discount = newTotalSessions.find(
          (data: any) => data.num_classes == finalSaveState.totalSession
        );
        return current_discount ? current_discount.discount : 0;
      }
      return 0;
    }
    return 0;
  };

  const getPendingAmount = () => {
    if (finalSaveState.totalSession && pricePerClass) {
      const price_per_class =
        pricePerClass - (Number(getInstructorDiscount()) / 100) * pricePerClass;
      const pendingTotal = (price_per_class * finalSaveState.totalSession) -
        finalSaveState.amountPaid
        - finalSaveState.dhunguruDiscount;

      return pendingTotal;
    } else {
      return 0;
    }
  };

  const getInstructorDiscountPrice = () => {
    if (finalSaveState.totalSession && pricePerClass) {
      const total_temp =
        Number(pricePerClass) * Number(finalSaveState.totalSession);
      const instru_after_discount =
        total_temp - (Number(getInstructorDiscount()) / 100) * total_temp;
      return instru_after_discount;
    } else {
      return 0;
    }
  };

  const getPriceAfterDhunDiscount = () => {
    if (finalSaveState.totalSession && pricePerClass) {
      const instr_discount = getInstructorDiscountPrice();
      return instr_discount - finalSaveState.dhunguruDiscount;
    } else {
      return 0;
    }
  };


  const getFinalPrice = () => {
    if (finalSaveState.totalSession && pricePerClass) {
      const dhunguruDiscount_price = getPriceAfterDhunDiscount();
      const after_gst = dhunguruDiscount_price + 0.18 * dhunguruDiscount_price;
      return after_gst;
    } else {
      return 0;
    }
  };

  const addNewDealHandler = () => {
    if (validateFreshworksId() == false) {
      toast.error('Freshworks Id does not exist for this user or invalid freshworks id');
      return;
    }
    setShowNewDealModal(true);
  }

  const validateFreshworksId = () => {
    if (FreshWorksId == null || FreshWorksId.length < 1) {
      return false;
    }
    // Convert FreshWorksId to a number
    const numericValue = Number(FreshWorksId);

    // Check if conversion was successful (NaN indicates invalid number)
    if (isNaN(numericValue)) {
      return false;
    }
    return true;
  }



  const handleChangeDealPipeline = (e: any, type: any, selectType?: any) =>{
    if(e==undefined || e==null || e.value==null || e.value==undefined){
      setPipelineStageOptions(null);
      return;
    }
    const options = getStageOptionsByPipeline(e.value);
    setPipelineStageOptions(options);
    handleChangeNewDeals(e, type, selectType);
  }

  return (
    <>
      <CardBasic
        body={
          <>
            <div className="d-flex justify-content-end align-items-center">
              <div className="text-center mx-2 cursor-pointer">
                <RiSecurePaymentFill
                  onClick={() => {
                    navigate("/admin/razorpay");
                  }}
                />
                <div
                  className="fs-10 fw-bold"
                  onClick={() => {
                    navigate("/admin/razorpay");
                  }}
                >
                  Payment Link
                </div>
              </div>
              <div className="text-center mx-2 cursor-pointer" onClick={() => setShowEnrollmentModal(true)}>
                <RiShoppingCart2Line
                />
                <div className="fs-10 fw-bold">New Enrollment</div>
              </div>
              <div className="text-center mx-2 cursor-pointer" onClick={() => setShowSessionModal(true)} >
                <TfiBlackboard />
                <div className="fs-10 fw-bold">New Session</div>
              </div>
              <div className="text-center mx-2 cursor-pointer" onClick={() => setShowDealModal(true)} >
                <AiOutlineDollar />
                <div className="fs-10 fw-bold">Add Existing Deal</div>
              </div>
              <div className={`text-center mx-2`} style={{ cursor: (!validateFreshworksId()) ? "not-allowed" : "pointer" }} onClick={() => addNewDealHandler()} >
                <AiOutlineDollar />
                <div className="fs-10 fw-bold">New Deal</div>
              </div>
            </div>
          </>
        }
      />

      <SideModal
        show={showSessionModal}
        handleClose={() => setShowSessionModal(false)}
        body={
          <div className="w-100  mt-1" style={{ height: "90vh" }}>
            <div className="form-group ">
              <label className="fw-bold text-secondary fs-12">
                Select Enrollment
              </label>
              <div className="selector">
                <Select
                  options={selectValue}
                  value={enrollment}
                  isSearchable={true}
                  placeholder="Select Enrollments"
                  onChange={(e: any) => {
                    setEnrollment(e);
                  }}
                  isClearable
                />
                {errors[0] && (
                  <div className="invalid-feedback d-block">
                    Please select a valid enrollment id
                  </div>
                )}
              </div>
            </div>

            <div className="form-group my-4">
              <label className="fw-bold text-secondary fs-12">Date</label>
              <CalenderInput
                setValue={(date: any) => {
                  setDate(date);
                }}
                selected={date}
              />
              {errors[1] && (
                <div className="invalid-feedback d-block">
                  Please select Date
                </div>
              )}
            </div>
            <button
              className="btn btn-primary btn-block mt-6"
              onClick={(e) => {
                createSchedule(e);
              }}
              disabled={loading}
            >
              {loading && (
                <span className="spinner-border spinner-border-sm mr-2"></span>
              )}
              Create Schedule
            </button>
          </div>
        }
        title="Add Session"
      />

      <SideModal
        show={showEnrollmentModal}
        handleClose={() => setShowEnrollmentModal(false)}
        title="Add Enrollment"
        body={
          <>
            <Form.Group className="mt-2">
              <Form.Label className="fw-bold text-secondary fs-12">
                Select Guru
              </Form.Label>
              <AsyncSelect
                name="InstructorId"
                loadOptions={fetchInstructorData}
                onChange={(option) => handelInstructorChanges(option)}
                isClearable={true}
              />
            </Form.Group>
            {/* <Form.Group className="mt-2">
              <Form.Label className="fw-bold text-secondary fs-12">
                Select the Student
              </Form.Label>

              <AsyncSelect
                name="studentId"
                loadOptions={fetchStudentData}
                onChange={setInputStudentValue}
                isClearable={true}
              />
            </Form.Group> */}
            <Form.Group className="mt-2">
              <Form.Label className="fw-bold text-secondary fs-12">
                Select the Course
              </Form.Label>
              <Select
                name="courseId"
                options={instructorCourses}
                isClearable={true}
                onChange={setInputCourseValue}
              />
            </Form.Group>
            {totalSessions && totalSessions.length > 0 && (
              <>
                <Form.Group className="mt-2">
                  <Form.Label className="fw-bold text-secondary fs-12">
                    Total Sessions
                  </Form.Label>

                  <Select
                    options={totalSessions}
                    onChange={(e: any) => handleSelectChange(e, "totalSession")}
                  />
                </Form.Group>
                <Form.Group className="mt-2">
                  <Form.Label className="fw-bold text-secondary fs-12">
                    Price per class
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="pricePerClass"
                    disabled={true}
                    value={pricePerClass}
                  />
                  <Form.Group className="mt-2">
                    <Form.Label className="fw-bold text-secondary fs-12">
                      Instructor Discount
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="instructorDiscount"
                      disabled={true}
                      value={getInstructorDiscount()}
                    />
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label className="fw-bold text-secondary fs-12">
                      Dhunguru Discount
                    </Form.Label>
                    <Form.Control
                      type="number"
                      name="dhunguruDiscount"
                      value={finalSaveState.dhunguruDiscount}
                      defaultValue={finalSaveState.dhunguruDiscount}
                      min={0}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label className="fw-bold text-secondary fs-12">
                      Amount Paid (without GST)
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="amountPaid"
                      onChange={handleChange}
                      value={finalSaveState.amountPaid}
                    />
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label className="fw-bold text-secondary fs-12">
                      Pending Amount
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="pendingAMount"
                      disabled={true}
                      value={getPendingAmount()}
                    />
                  </Form.Group>
                </Form.Group>
              </>
            )}
            <Form.Group className="mt-2">
              <Form.Label className="fw-bold text-secondary fs-12">
                Coordinator
              </Form.Label>
              <Form.Control
                type="text"
                name="coordinator"
                onChange={handleChange}
              />
            </Form.Group>
            <div
              className="bg-muted rounded-3 mt-3 p-3 fs-12"
              style={{ backgroundColor: "#f1f1f1" }}
            >
              <div className="fw-bold text-secondary">
                <div>
                  Price per Class:{" "}
                  <span className="text-black">{pricePerClass}</span>
                </div>
                <div>
                  {" "}
                  Total Classes:{" "}
                  <span className="text-black">
                    {" "}
                    x {finalSaveState.totalSession}
                  </span>
                </div>
                <div>
                  {" "}
                  Total Amount:{" "}
                  <span className="text-secondary">
                    {" = "}
                    {finalSaveState.totalSession * pricePerClass}
                  </span>
                </div>
                <div>
                  {" "}
                  Instructor Discount:{" "}
                  <span className="text-danger">
                    {" "}
                    - {getInstructorDiscount()} %
                  </span>
                </div>
                <div>
                  {" "}
                  Price After Discount:{" "}
                  <span className="text-black">
                    {" "}
                    {getInstructorDiscountPrice()}
                  </span>
                </div>
                <div>
                  {" "}
                  Dhunguru Discount:{" "}
                  <span className="text-danger">
                    {" "}
                    - INR {finalSaveState.dhunguruDiscount}
                  </span>
                </div>
                <div>
                  {" "}
                  Price After Dhun. Discount:{" "}
                  <span className="text-black">
                    {" "}
                    {getPriceAfterDhunDiscount()}
                  </span>
                </div>
                <div>
                  {" "}
                  Total Price with GST:{" "}
                  <span className="text-success">
                    {" "}
                    {getFinalPrice() > 0
                      ? getFinalPrice()
                      : " -- All Fields Are Required -- "}
                  </span>
                </div>
              </div>
            </div>
            <Button className="mt-4" onClick={onSubmit} disabled={loading}>
              Create
            </Button>
          </>
        }
      />

      <SideModal
        show={showDealModal}
        handleClose={() => setShowDealModal(false)}
        body={
          <div className="w-100  mt-1" style={{ height: "90vh" }}>
            <Form.Group className="mt-2">
              <Form.Label className="fw-bold text-secondary fs-12">
                Deal Id
              </Form.Label>
              <Form.Control
                type="text"
                name="id"
                value={dealsData.id}
                onChange={(e) => { handleChangeDeals(e, 'input') }}
              />
            </Form.Group>
            <Form.Group className="mt-2">
              <Form.Label className="fw-bold text-secondary fs-12">
                Deal Name
              </Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={dealsData.name}
                onChange={(e) => { handleChangeDeals(e, 'input') }}
              />
            </Form.Group>
            <Form.Group className="mt-2">
              <Form.Label className="fw-bold text-secondary fs-12">
                Amount
              </Form.Label>
              <Form.Control
                type="number"
                name="amount"
                value={dealsData.amount}
                onChange={(e) => { handleChangeDeals(e, 'input') }}
              />
            </Form.Group>
            <Form.Group className="mt-2">
              <Form.Label className="fw-bold text-secondary fs-12">
                Status
              </Form.Label>
              <Select
                name="status"
                options={paymentStatus}
                isClearable={true}
                onChange={(e) => { handleChangeDeals(e, 'select', 'status') }}
              />
            </Form.Group>
            <Form.Group className="mt-2">
              <Form.Label className="fw-bold text-secondary fs-12">
                Deal Type
              </Form.Label>
              <Select
                name="status"
                options={Object.entries(dealsType).map(([key, value]): any => ({
                  value: value,
                  label: value,
                }))}
                isClearable={true}
                onChange={(e) => { handleChangeDeals(e, 'select', 'dealType') }}
              />
            </Form.Group>
            <button
              className="btn btn-primary btn-block mt-6"
              onClick={(e) => {
                console.log('deals : ', dealsData);
                addDeal();
              }}
              disabled={loading}
            >
              {loading && (
                <span className="spinner-border spinner-border-sm mr-2"></span>
              )}
              Add Deal
            </button>
          </div>
        }
        title="Add Existing Deal"
      />


      <SideModal
        show={showNewDealModal}
        handleClose={() => setShowNewDealModal(false)}
        body={
          <div className="w-100  mt-1" style={{ height: "90vh" }}>
            <Form.Group className="mt-2">
              <Form.Label className="fw-bold text-secondary fs-12">
                Select Guru
              </Form.Label>
              <AsyncSelect
                name="InstructorId"
                loadOptions={fetchInstructorData}
                onChange={(option) => handleChangeNewDeals(option, 'select', 'teacher')}
                isClearable={true}
              />
            </Form.Group>
            <Form.Group className="mt-2">
              <Form.Label className="fw-bold text-secondary fs-12">
                Amount
              </Form.Label>
              <Form.Control
                type="number"
                name="amount"
                value={newDealsData.amount}
                onChange={(e) => { handleChangeNewDeals(e, 'input') }}
              />
            </Form.Group>
            <Form.Group className="mt-2">
              <Form.Label className="fw-bold text-secondary fs-12">
                Status
              </Form.Label>
              <Select
                name="status"
                options={paymentStatus}
                isClearable={true}
                onChange={(e) => { handleChangeNewDeals(e, 'select', 'status') }}
              />
            </Form.Group>
            <Form.Group className="mt-2">
              <Form.Label className="fw-bold text-secondary fs-12">
                Select Subject
              </Form.Label>
              <Select
                name="subject"
                options={freshworksInstrument.map((value, index): any => ({
                  value: value,
                  label: value,
                }))}
                isClearable={true}
                onChange={(e) => { handleChangeNewDeals(e, 'select', 'subject') }}
              />
            </Form.Group>
            <Form.Group className="mt-2">
              <Form.Label className="fw-bold text-secondary fs-12">
                Deal Pipeline
              </Form.Label>
              <Select
                name="status"
                options={Object.entries(dealsType).map(([key, value]): any => ({
                  value: value,
                  label: value,
                }))}
                isClearable={true}
                onChange={(e) => { handleChangeDealPipeline(e, 'select', 'dealType') }}
              /> 
            </Form.Group>
            <Form.Group className="mt-2">
              <Form.Label className="fw-bold text-secondary fs-12">
                Deal Stage
              </Form.Label>
              <Select
                name="status"
                options={pipelineStageOptions}
                isClearable={true}
                onChange={(e) => { handleChangeNewDeals(e, 'select', 'pipelineStage') }}
              />
            </Form.Group>
            <Form.Group className="mt-2">
              <Form.Label className="fw-bold text-secondary fs-12">
                Deal Name Preview(Generated Automatically)
              </Form.Label>
              <p style={{fontSize:'12px'}}>{newDealsData.name.length<1 ? "Deal name will be generated after selecting guru and deal type" : newDealsData.name}</p>
            </Form.Group>
            <button
              className="btn btn-primary btn-block mt-6"
              onClick={(e) => {
                addNewDeal();
              }}
              disabled={loading}
            >
              {loading && (
                <span className="spinner-border spinner-border-sm mr-2"></span>
              )}
              Add Deal
            </button>
          </div>
        }
        title="Add New Deal"
      />
    </>
  );
}
