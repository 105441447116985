import { Box, Button, Card, CardContent, Grid } from "@mui/material";
import React, { useState } from "react";
import SearchSectionHeader from "../../../components/Headers/SearchSectionHeader";
import HighlightString from "../HighLighting";

const btn = {
  backgroundColor: "#2ecc71",
  fontSize: "9px",
  textTransform: "none",
  color: "white",
  padding: "2px",
  margin: "2px",
  "&:hover": {
    backgroundColor: "#2ecc71",
    color: "white",
  },
};

const btn2 = {
  backgroundColor: "#e74c3c",
  fontSize: "9px",
  textTransform: "none",
  color: "white",
  padding: "2px",
  margin: "0",
  "&:hover": {
    backgroundColor: "#e74c3c",
    color: "white",
  },
};

const ptagbold = {
  padding: "0",
  margin: "0",
  fontWeight: "bold",
  fontSize: "12px",
};

const ptag = {
  padding: "0",
  margin: "0",
  fontSize: "12px",
};

function Invoices(props: any) {
  const invoices = props.invoices;
  const search = props.search;
  const [displayAll, setDisplayAll] = useState(false);
  return (
    <>
      {invoices.length !== 0 ? (
        <div className="search-result-individual">
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <SearchSectionHeader text="Invoices" />
            {invoices.length > 4 ? (
              <Button onClick={() => setDisplayAll(!displayAll)}>
                {displayAll ? "Show Less" : "Show More"}
              </Button>
            ) : (
              ""
            )}
          </Box>
          <Grid container spacing={2}>
            {displayAll
              ? invoices.map((invoice: any) => {
                  return (
                    <Grid item md={3} sm={6} xs={6}>
                      <Card sx={{ height: "100%" }}>
                        <CardContent>
                          <Grid
                            container
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Grid item>
                              <Button sx={btn}>
                                <HighlightString
                                  strTarget={invoice?.status}
                                  subStr={search}
                                />
                              </Button>
                            </Grid>
                            <Grid item>
                              <p style={ptagbold}>
                                <HighlightString
                                  strTarget={invoice?.receipt_id}
                                  subStr={search}
                                />
                              </p>
                            </Grid>
                          </Grid>
                          <p style={ptag}>
                            Comment :{" "}
                            <HighlightString
                              strTarget={invoice?.comments}
                              subStr={search}
                            />
                          </p>
                          <p style={ptag}>
                            Type :{" "}
                            <HighlightString
                              strTarget={invoice?.type}
                              subStr={search}
                            />
                          </p>
                        </CardContent>
                      </Card>
                    </Grid>
                  );
                })
              : invoices.map((invoice: any, i: number) => {
                  if (i < 4) {
                    return (
                      <Grid item md={3} sm={6} xs={6}>
                        <Card sx={{ height: "100%" }}>
                          <CardContent>
                            <Grid
                              container
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <Grid item>
                                <Button sx={btn}>
                                  <HighlightString
                                    strTarget={invoice?.status}
                                    subStr={search}
                                  />
                                </Button>
                              </Grid>
                              <Grid item>
                                <p style={ptagbold}>
                                  <HighlightString
                                    strTarget={invoice?.receipt_id}
                                    subStr={search}
                                  />
                                </p>
                              </Grid>
                            </Grid>
                            <p style={ptag}>
                              Comment :{" "}
                              <HighlightString
                                strTarget={invoice?.comments}
                                subStr={search}
                              />
                            </p>
                            <p style={ptag}>
                              Type :{" "}
                              <HighlightString
                                strTarget={invoice?.type}
                                subStr={search}
                              />
                            </p>
                          </CardContent>
                        </Card>
                      </Grid>
                    );
                  }
                })}
          </Grid>
        </div>
      ) : (
        "No Invoices data to show"
      )}
    </>
  );
}

export default Invoices;
