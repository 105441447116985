import * as React from "react";
import { useLocation, useNavigate } from "react-router";
import { baseUrl, privateConfig, privateConfigWithSection } from "../../../routes/config";
import "./style.css";
import { Accordion, Image } from "react-bootstrap";
import dhunLogo from "../../../assets/logo.png";
import { Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RolesContext } from "../../../routes/private.context";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { IoSearch } from "react-icons/io5";
import { BiChevronDown } from "react-icons/bi";
console.log(privateConfig, 'pvt')
const menu = privateConfig.filter((routes) => routes.show === true);
const sectionedMenu = privateConfigWithSection.map((section) => ({
  ...section,
  options: section.options.filter(option => option.show == true),
}));
console.log(sectionedMenu);
interface IRouter {
  path: string;
  show?: Boolean;
  element: any;
  name?: string;
  icon?: any;
  inActiveIcon?: any;
}
function SideDrawer() {
  const [openIndex, setOpenIndex] = React.useState<number | null>(null); 
  const navigate = useNavigate();
  const location = useLocation();
  const { roles, getUserRoles } = React.useContext(RolesContext);
  const checkIfRouteAllowed = (path: string) => {
    return roles.find((role: any) => role.role == path && role.allowed == true)
      ? true
      : false;
  };

  const selectedRoute = (index: number, item: IRouter) => {
    return (
      <div
        key={index}
        className="xcn-sidebar-links active xcn-link-pointer activeClassTextColor d-flex align-items-center"
        onClick={() => navigate(baseUrl + "/" + item.path)}
      >
        {/* <FontAwesomeIcon icon={item?.icon} /> */}
        <item.icon />

        <div className="ms-2">{item.name}</div>
      </div>
    );
  };

  const notSelectedRoute = (index: number, item: IRouter) => {
    return (
      <div
        key={index}
        className="xcn-sidebar-links-inactive xcn-link-pointer d-flex align-items-center"
        onClick={() => navigate(baseUrl + "/" + item.path)}
      >
        {/* <FontAwesomeIcon icon={item?.icon} /> */}
        <item.icon />

        <div className="ms-2">{item.name}</div>
      </div>
    );
  };

  const handleLogout = () => {
    sessionStorage.removeItem("authKey");
    window.location.reload();
  };

  return (
    <>
      <Nav
        defaultActiveKey="0"
        className="flex-column xcn-dashboard-sub-nav flex-sm-column overflow-hidden"
        style={{
          backgroundColor: "#0C0033",
          width: "14rem",
        }}
      >
        <div className="d-flex justify-content-center align-items-center mb-4">
          <div className="mt-4">
            <Image src={dhunLogo} height="30px" />
          </div>
        </div>

        {/* {menu.map((data, index: any) => {
          if (data.show == true && checkIfRouteAllowed(data.role)) {
            return (
              <>
                {location.pathname.split("/")[2] === data.path
                  ? selectedRoute(index, data)
                  : notSelectedRoute(index, data)}
              </>
            );
          }
        })} */}

        {sectionedMenu.map((item: any, index: any) => {
          return (
            <SidebarItem key={index} options={item.options} title={item.sectionName} ItemIcon={item.icon} isOpen={openIndex === index} onToggle={() => setOpenIndex(openIndex === index ? null : index)}/>
          )
        })}

        <hr />
        <div
          className="fw-bold text-center mt-4 text-white xcn-text-15 cursor-pointer"
          onClick={handleLogout}
        >
          <div className="d-flex justify-content-center align-items-center">
            <FontAwesomeIcon icon={faSignOutAlt} className="text-white" />
          </div>
          Logout
        </div>
      </Nav>
    </>
  );
}

const SidebarItem = ({ options, title, ItemIcon, isOpen, onToggle }: any) => {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { roles, getUserRoles } = React.useContext(RolesContext);

  const checkIfRouteAllowed = (path: string) => {
    return roles.find((role: any) => role.role == path && role.allowed == true)
      ? true
      : false;
  };

  const selectedRoute = (index: number, item: IRouter) => {
    return (
      <div
        key={index}
        className="xcn-sidebar-links active xcn-link-pointer activeClassTextColor d-flex align-items-center"
        onClick={() => navigate(baseUrl + "/" + item.path)}
      >
        {/* <FontAwesomeIcon icon={item?.icon} /> */}
        <item.icon />

        <div className="ms-2">{item.name}</div>
      </div>
    );
  };

  const notSelectedRoute = (index: number, item: IRouter) => {
    return (
      <div
        key={index}
        className="xcn-sidebar-links-inactive xcn-link-pointer d-flex align-items-center"
        onClick={() => navigate(baseUrl + "/" + item.path)}
      >
        {/* <FontAwesomeIcon icon={item?.icon} /> */}
        <item.icon />

        <div className="ms-2">{item.name}</div>
      </div>
    );
  };

  return (
    <div className={isOpen ? "sidebar-item open" : "sidebar-item"}>
      <div className="sidebar-title d-flex flex-row justify-content-between align-items-center" onClick={()=>{onToggle()}}>
        <span className=" d-flex flex-row justify-content-start gap-2 align-items-center">
          <ItemIcon />
          {title}
        </span>
        <span>
          <BiChevronDown className="toggle-btn" />
        </span>
      </div>
      <div className="sidebar-content">
        {options && options.length > 0 && options.map((data: any, index: any) => {
          if (data.show == true && checkIfRouteAllowed(data.role)) {
            return (
              <div className=" my-1">
                {location.pathname.split("/")[2] === data.path
                  ? selectedRoute(index, data)
                  : notSelectedRoute(index, data)}
              </div>
            );
          }
        })}
      </div>
    </div>
  )
}

export default SideDrawer;
