interface ISearchSectionHeader {
    text: string
}

export default function SearchSectionHeader(props: ISearchSectionHeader) {
    return (
        <div className="d-flex justify-content-between align-items-center ">
            <h5 className="fw-bold fs-grey">{props.text}</h5>
        </div>
    )
}