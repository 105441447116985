import { Form, Image, Row } from "react-bootstrap";
import logo from "../../../assets/dhunguru_logo.svg";
import "./search.css";
import Courses from "../sections/Courses";
import Students from "../sections/Students";
import Instructors from "../sections/Instructors";
import Enrollments from "../sections/Enrollments";
import Invoices from "../sections/Invoices";
import Requests from "../sections/Requests";
import Sessions from "../sections/Sessions";
import Logs from "../sections/Logs";

interface ISearchResult {
  search: string;
  searchContent: any;
  searchKeyword: string;
  activeCategory: any,
}

export default function SearchResult(props: ISearchResult) {
  return (
    <>
      <div className="mb-3">
        {/* <Courses
                    courses={props.searchContent?.courses || []}
                    search={props.searchKeyword || ""}
                /> */}
        {props.activeCategory=="Instructors" && <Instructors
          instructors={props.searchContent?.instructors?.data || []}
          search={props.searchKeyword || ""}
        />}
        {props.activeCategory=="Students" && <Students
          students={props.searchContent?.students || []}
          search={props.searchKeyword || ""}
        />}
        {props.activeCategory=="Enrollments" && <Enrollments
          enrollments={props.searchContent?.enrollments || []}
          search={props.searchKeyword || ""}
        />}
        {props.activeCategory=="Sessions"  && <Sessions
          sessions={props.searchContent?.sessions || []}
          search={props.searchKeyword || ""}
        />}
        {props.activeCategory=="Invoices"  && <Invoices
          invoices={props.searchContent?.invoices || []}
          search={props.searchKeyword || ""}
        />}
        {props.activeCategory=="Requests"  && <Requests
          requests={props.searchContent?.requests || []}
          search={props.searchKeyword || ""}
        />}

        {/* <Logs
                    logs={props.searchContent?.logs || []}
                    search={props.searchKeyword || ""}
                /> */}
      </div>
    </>
  );
}
