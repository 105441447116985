import React, { useEffect, useState } from 'react'
import { Badge, Button, Form } from 'react-bootstrap';
import SideModal from '../SideModal/SideModal';
import { AdminService } from '../../services/admin.service';
import toast from 'react-hot-toast';
import UserService from '../../services/user.service';

interface IEditVisibilityModel {
    user_id: any,
    onHide: any
}

export default function EditVisibilityModel (props: IEditVisibilityModel) {

    const [counsellor, setCounsellor] = useState<boolean>(false);
    const [coordinator, setCoordinator] = useState<boolean>(false);


    const getuser = async () => {
        await UserService.getAdminById(props.user_id).then((res) => {
            if (res.status == 200) {
                const visibilty = res.data.adminVisibility;
                setCounsellor(visibilty.includes("COUNSELLOR") ? true : false);
                setCoordinator(visibilty.includes("COORDINATOR") ? true : false);
            }
        }).catch((error) => {
            console.log(error);
            toast.error('Something went wrong! Please try again after sometime');
        })
    }

    const updateUser = async () => {
        let adminVisibility = []
        if (counsellor) {
            adminVisibility.push("COUNSELLOR")
        }
        if (coordinator) {
            adminVisibility.push("COORDINATOR")
        }
        await AdminService.updateUserData(props.user_id, { adminVisibility }).then((res) => {
            if (res.status == 200) {
                toast.success('Admin visibility updated');
            }
        }).catch((error) => {
            console.log(error);
            toast.error('Something went wrong! Please try again after sometime');
        })

        props.onHide();
    }

    useEffect(() => {
        if(props.user_id){
            getuser()
        }
    }, [props.user_id])


    return (
        <SideModal
            title="Edit Admin Visibility"
            show={props.user_id ? true : false}
            handleClose={props.onHide}
            body={
                <>
                    <div style={{ height: "80vh" }}>
                        <Form.Group className="mb-3" controlId="formBasicCheckbox">
                            <Form.Check type="checkbox" label="Counsellor" checked={counsellor} onChange={(e) => setCounsellor(e.target.checked)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicCheckbox">
                            <Form.Check type="checkbox" label="Coordinator" checked={coordinator} onChange={(e) => setCoordinator(e.target.checked)} />
                        </Form.Group>
                    </div>
                    <div>
                       <Button onClick={updateUser} variant="primary">Update</Button>
                    </div>
                </>
            }
        />
    )
}