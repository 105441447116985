import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import useQueryParams from "../../../Hooks/useQueryParams";
import SearchResult from "./SearchResult";
import { AdminService } from "../../../services/admin.service";
import { Form, Image } from "react-bootstrap";
import logo from "../../../assets/logo_dark.png";
import "./search.css";
import { BiChevronRight } from "react-icons/bi";

const searchCategories = [
  {
    title: "Instructors",
  },
  {
    title: "Students",
  },
  {
    title: "Enrollments",
  },
  {
    title: "Sessions",
  },
  {
    title: "Invoices",
  },
  {
    title: "Requests",
  },
]

const SearchPage = (props: any) => {
  const [searchContent, setSearchContent] = useState<any>(undefined);
  const [activeCategory, setActiveCategory] = useState<any>("Instructors");
  const { search }: any = useQueryParams("search");
  const [searchResultCount, setSearchResultCount] = useState<any>({
    Instructors: null,
    Students: null,
    Enrollments: null,
    Sessions: null,
    Invoices: null,
    Requests: null,
  })
  const navigate: any = useNavigate();

  const setSearchParams = (searchParams: string) => {
    navigate(`/admin/search?search=${searchParams}`);
  };

  const getSearchData = async () => {
    if (search?.length > 2) {
      await AdminService.search(search).then((res) => {
        if (res.status === 200) {
          // console.log(res.data);
          setSearchResultCount({
            Instructors: res.data.instructors.data.length || 0,
            Students: res.data.students.length || 0,
            Enrollments: res.data.enrollments.length || 0,
            Sessions: 0,
            Invoices: 0,
            Requests: 0,
          })
          setSearchContent(res.data);
        }
      });
    } else {
      setSearchResultCount({
        Instructors: null,
        Students: null,
        Enrollments: null,
        Sessions: null,
        Invoices: null,
        Requests: null,
      })
      setSearchContent(undefined);
    }
  };

  useEffect(() => {
    getSearchData();
  }, [search]);

  return (
    <div>
      <div className="d-flex justify-content-start align-items-center m-4">
        <Image src={logo} height="25px" />
        <Form.Control
          type="text"
          value={props.search}
          onChange={(e) => {
            setSearchParams(e.target.value);
          }}
          className="ms-4 dhun-search-bar-top"
          placeholder="Type Here to Search..."
        />
      </div>
      <hr className="text-muted" />
      <div className=" d-flex flex-row search-page-container">
        <SideBar activeCategory={activeCategory} setActiveCategory={setActiveCategory} searchResultCount={searchResultCount}/>
        <div className="search-result">
          {searchContent ? (
            <SearchResult
              search={search}
              searchContent={searchContent}
              searchKeyword={search}
              activeCategory={activeCategory}
            />
          ) : (
            <>
              <div className="p-4 fst-italic text-secondary">
                Type text to search
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const SideBar = ({activeCategory, setActiveCategory, searchResultCount}:any) => {
  return (
    <div className="search-sidebar">
      {/* <div className=" fs-3 px-2">
        Categories
      </div>
      <hr /> */}
      <div className=" d-flex flex-column gap-2 px-2">
        {searchCategories.map((item: any, index: number) => {
          return (
            <div className={activeCategory==item.title ? "search-sidebar-item-active" : "search-sidebar-item"} onClick={()=>{setActiveCategory(item.title)}}>
              {item.title + ((searchResultCount[item.title]==null || searchResultCount[item.title]==undefined) ? "": " " + `(${searchResultCount[item.title] ? searchResultCount[item.title] : 0})`)}
              {activeCategory==item.title && <BiChevronRight/>}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default SearchPage;
