// export const freshworksInstrument = [
//     "Guitar",
//     "Piano",
//     "Drums",
//     "Keyboard",
//     "Violin",
//     "Ukulele",
//     "Cajon",
//     "Harmonium",
//     "Flute",
//     "Tabla",
//     "Veena",
//     "Trumpet",
//     "Mridangam",
//     "Sitar",
//     "Singing",
//     "Song Writing",
//     "Music Composition",
//     "Music Theory",
//     "Rap",
//     "Music Therapy",
//     "DJing",
//     "Dhol",
//     "Harmonica",
//     "Mandolin",
//     "Double Bass",
//     "Cello",
// ]

export const freshworksInstrument = [
    "guitar",
    "piano",
    "drums",
    "keyboard",
    "violin",
    "ukulele",
    "cajon",
    "harmonium",
    "flute",
    "tabla",
    "veena",
    "trumpet",
    "mridangam",
    "sitar",
    "singing",
    "songWriting",
    "musicComposition",
    "musicTheory",
    "rap",
    "musicTherapy",
    "dJing",
    "dhol",
    "harmonica",
    "mandolin",
    "doubleBass",
    "cello"
]