import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import CardBasic from "../../../components/Card/card.basic";
import SearchSectionHeader from "../../../components/Headers/SearchSectionHeader";
import HighlightString from "../HighLighting";
import classes from "./index.module.css";

const textStyle = {
  display: "-webkit-box",
  maxWidth: "300px",
  webkitLineClamp: "1",
  webkitBoxOrient: "vertical",
  overflow: "hidden",
};

function Enrollments(props: any) {
  const sessions = props?.sessions;
  const search = props.search;
  const navigate = useNavigate();

  return (
    <>
      {sessions.length > 0 ? (
        <div className="search-result-individual">
          <SearchSectionHeader text="Sessions" />
          <div className="d-flex justify-content-start flex-wrap overflow-auto">
            {sessions.map((session: any, index: number) => {
              return (
                <div className="mx-1 p-3 cursor-pointer">
                  <CardBasic
                    body={
                      <div>
                        <div className="d-flex justify-content-between">
                          <div className="fw-bold fs-12">
                            {session.type === "demo" ? (
                              <span className="text-muted">demo</span>
                            ) : (
                              <span className="fs-dhunprimary">paid</span>
                            )}
                          </div>
                          <div className="fw-bold fs-12 fs-primary">
                            {session.bookingId}
                          </div>
                        </div>
                        <div className="mt-3">
                          <span
                            className="h6 fw-bold text-grey"
                            style={{ ...textStyle }}
                          >
                            {session.title || "--"}
                          </span>
                        </div>
                        <div className="mt-2">
                          <span className="text-muted">
                            on{" "}
                            <span className="fw-bold h6">
                              {moment(session?.date_time).format(
                                "DD-MM-YYYY  hh:mm a"
                              ) || "--"}
                            </span>
                          </span>
                        </div>
                        <div>
                          <span className="fs-grey fs-12">
                            {session?.instructor.firstName +
                              " " +
                              session?.instructor.lastName || "--"}
                            {" <> "}
                            {session?.student.firstName +
                              " " +
                              session?.student.lastName || "--"}
                          </span>
                        </div>
                      </div>
                    }
                  />
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        "No Sessions data to show"
      )}
    </>
  );
}

export default Enrollments;
