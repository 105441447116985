import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Col,
  Container,
  Dropdown,
  Form,
  FormCheck,
  Row,
  Table,
} from "react-bootstrap";
import { NormalText } from "../Text/TableText.formats";
import moment from "moment";
import { useNavigate } from "react-router";
import "./table.css";
import toast from "react-hot-toast";
import UserService from "../../services/user.service";
import { UserRoleEnum } from "../../enum/role.enum";
import CustomToggle from "../Menu/CustomMenu";
import { BiBlock, BiDotsVertical, BiLockOpen } from "react-icons/bi";
import { GrEdit, GrPowerReset } from "react-icons/gr";
import ResetPassword from "../Modals/ResetPassword.modal";
import EditUser from "../Modals/EditUser.modal";
import EditRoles from "../Modals/EditRoles.modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical, faTv, faUser } from "@fortawesome/free-solid-svg-icons";
import StringHelper from "../Helper/string.helper";
import RolesModal from "../Modals/Roles.modal";
import AddClassesNoModal from "../Modals/AddClassesNo.modal";
import EditVisibilityModel from "../Modals/EditVisibilty.model";

// import EditRoles from "../Modals/EditRoles.modal";

interface IAllUserTable {
  tableData: any;
  checkIfRoleAllowed: any;
  handleRoleChange: any;
  reloadData: any;
}

const RoleTable = ({
  tableData,
  checkIfRoleAllowed,
  handleRoleChange,
  reloadData,
}: IAllUserTable) => {
  const [passwordChangeUserId, setPasswordChangeUserId] = useState<string>();
  const [editUser, setEditUser] = useState<any>({});
  const [userId, setUserId] = useState<string>();

  const [showRolesModal, setShowRolesModal] = useState<any>(undefined);
  const [showClassesModal, setShowClassesModal] = useState<any>(undefined)

  const [visibilityModel, setVisibiltyModel] = useState<any>(undefined);

  const handleBlockUnblock = async (userId: string) => {
    await UserService.blockUnblockUser(userId).then((res: any) => {
      if (res.status === 200) {
        toast.success("Updated");
        reloadData();
      }
    });
  };


  useEffect(() => {
    reloadData();
  }, [editUser, userId, visibilityModel]);

  return (
    <>
      <ResetPassword
        userId={passwordChangeUserId}
        setUserId={setPasswordChangeUserId}
      />

      <EditUser
        user={{
          _id: editUser._id,
          firstName: editUser.firstName,
          lastName: editUser.lastName,
          email: editUser.email,
          phone: editUser.phone,
        }}
        setUser={setEditUser}
      />

      <EditRoles userId={userId} setUserId={setUserId} />

      <Table striped hover size="sm" responsive>
        <thead>
          <tr>
            <th>S.No.</th>
            <th>Name</th>
            <th>Email</th>
            <th>Phone No.</th>
            <th>Log-In-Date</th>
            {/* <th colSpan={Object.keys(UserRoleEnum).length}>Roles</th> */}
            <th> Assigned Roles</th>
            <th>Admin Visibility</th>
            <th>Assign</th>
          </tr>
        </thead>
        <tbody>
          {tableData && tableData?.length > 0
            ? tableData?.map((data: any, index: any) => {
              // const last_verification = verification_details[verification_details.length - 1] || {};
              const rolesCount = data.adminRoles && data.adminRoles.filter((data: any) => data.allowed === true);

              return (
                <tr key={data?._id} style={{ height: "full" }}>
                  <td
                    style={{
                      fontSize: "12px",
                      fontWeight: "bold",
                      maxWidth: "10px",
                    }}
                  >
                    <NormalText value={index + 1} />
                  </td>

                  <td
                    style={{
                      fontSize: "12px",
                      fontWeight: "bold",
                      maxWidth: "50px",
                    }}
                  >
                    {/* firstname+lastname */}
                    <span className="fs-italic fs-12">
                      {data?.firstName ? (
                        data?.firstName + " "
                      ) : (
                        <Badge
                          bg="danger"
                          className="me-1 fs-12 cursor-pointer"
                        >
                          N/A
                        </Badge>
                      )}
                    </span>

                    <span className="fs-italic fs-12">
                      {data?.lastName ? (
                        data?.lastName
                      ) : (
                        <Badge
                          bg="danger"
                          className="me-1 fs-12 cursor-pointer"
                        >
                          N/A
                        </Badge>
                      )}
                    </span>
                  </td>

                  <td
                    style={{
                      fontSize: "12px",
                      fontWeight: "bold",
                      maxWidth: "80px",
                    }}
                  >
                    <span className="fs-12 cursor-pointer">
                      {data.email ? (
                        data.email
                      ) : (
                        <Badge
                          bg="danger"
                          className="me-1 fs-12 cursor-pointer"
                        >
                          N/A
                        </Badge>
                      )}
                    </span>
                  </td>
                  <td
                    style={{
                      fontSize: "12px",
                      fontWeight: "bold",
                      maxWidth: "50px",
                    }}
                  >
                    <span className="fs-12 cursor-pointer">
                      {data.phone ? (
                        data.phone
                      ) : (
                        <Badge
                          bg="danger"
                          className="me-1 fs-12 cursor-pointer"
                        >
                          N/A
                        </Badge>
                      )}
                    </span>
                  </td>

                  <td
                    style={{
                      fontSize: "12px",
                      fontWeight: "bold",
                      maxWidth: "40px",
                    }}
                  >
                    <span className="font-monospace fs-12">
                      {moment(data.lastLogin).format("DD MMM YYYY")}
                    </span>
                  </td>
                  <td style={{ maxWidth: "200px" }}>
                    {data.isBlocked ? <Badge bg="danger">Blocked</Badge> : 
                    rolesCount && rolesCount.length && <Badge bg="success" className="ms-1" style={{ cursor: 'pointer' }} onClick={() => setShowRolesModal(rolesCount)}>{ StringHelper.capitalizeFirstLetter(rolesCount[0]?.role) + " +"+ rolesCount?.length}</Badge>}
                  </td>

                  <td style={{ maxWidth: "200px" }}>
                    {data.adminVisibility 
                     && data.adminVisibility.length > 0 && 
                     data.adminVisibility.map((data: any, index: any) => {
                      return (
                        <Badge bg="success" className="ms-1">
                          {data}
                        </Badge>
                      );
                    })
                    }
                  </td>

                  <td>
                    <Dropdown>
                      <Dropdown.Toggle
                        as={CustomToggle}
                        id="dropdown-custom-components"
                      >
                        <FontAwesomeIcon icon={faEllipsisVertical} />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          className="text-secondary fs-12 align-items-center d-flex fw-bold"
                          onClick={() => {
                            setPasswordChangeUserId(data._id);
                          }}
                        >
                          <GrPowerReset className="me-1" /> Reset Password
                        </Dropdown.Item>

                        <Dropdown.Item
                          className="text-secondary fs-12 align-items-center d-flex fw-bold"
                          onClick={() => {
                            setEditUser({
                              _id: data._id,
                              email: data.email,
                              phone: data.phone,
                              firstName: data.firstName,
                              lastName: data.lastName,
                            });
                          }}
                        >
                          <GrEdit className="me-1" /> Edit User
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="text-secondary fs-12 align-items-center d-flex fw-bold"
                          onClick={() => {
                            setUserId(data._id);
                          }}
                        >
                          <span>
                            <FontAwesomeIcon icon={faUser} className="me-1" />
                            Edit Roles
                          </span>
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="text-secondary fs-12 align-items-center d-flex fw-bold"
                          onClick={() => {
                            setShowClassesModal(data._id);
                          }}
                        >
                          <span>
                            <FontAwesomeIcon icon={faTv} className="me-1" />
                            No. of Classes
                          </span>
                        </Dropdown.Item>

                        <Dropdown.Item
                          className="text-secondary fs-12 align-items-center d-flex fw-bold"
                          onClick={() => {
                            setVisibiltyModel(data._id);
                          }}
                        >
                          <span>
                            <FontAwesomeIcon icon={faTv} className="me-1" />
                            Edit Visibility
                          </span>
                        </Dropdown.Item>

                        <Dropdown.Item
                          className="text-danger fs-12 align-items-center d-flex fw-bold"
                          onClick={() => {
                            handleBlockUnblock(data._id);
                          }}
                        >
                          {data.isBlocked ? (
                            <span className="text-warning">
                              <BiLockOpen className="me-1" /> UnBlock User
                            </span>
                          ) : (
                            <>
                              <BiBlock className="me-1" /> Block User
                            </>
                          )}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              );
            })
            : "No data found"}
        </tbody>
      </Table>
      <RolesModal
        show={showRolesModal}
        onClose={() => setShowRolesModal(undefined)}
      />

      <AddClassesNoModal 
       show={showClassesModal}
       onClose={() => setShowClassesModal(undefined)}
      />

      <EditVisibilityModel 
        user_id={visibilityModel}
        onHide={() => setVisibiltyModel(undefined)}
        />
    </>
  );
};

export default RoleTable;
