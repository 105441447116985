import React from 'react'
import { Badge } from 'react-bootstrap';
import StringHelper from '../Helper/string.helper';
import SideModal from '../SideModal/SideModal';

interface IRoles {
    show: any,
    onClose: any
}

const RolesModal = ({ show, onClose }: IRoles) => {
    return (
        <SideModal
            title="Roles"
            show={show ? true : false}
            handleClose={onClose}
            body={
                <>
                    {show && show.length > 0 && show.map((data: any) => {
                        return (
                            <Badge bg="success" className="ms-1" >{StringHelper.capitalizeFirstLetter(data.role)}</Badge>
                        )
                    })}
                </>
            }
        />
    )
}

export default RolesModal