import BulkSessionBooking from "../views/private/BulkSessionBooking";
import HomePage from "../views/private/SearchPage";
import Login from "../views/public/Login";
import IRouterPrivate, { IRouterPrivateWithSection } from "./interfacePrivate";
import IRouterPublic from "./interfacePublic";
import Student from "../views/private/Components/Student";
import CourseDetails from "../views/private/Components/DetailCard/CourseDetails";
import Instructor from "../views/private/Components/Instructor";
import LogDetail from "../views/private/Components/LogDetail";
import Enrollment from "../views/private/Components/Enrollment";
import RazorPayIndex from "../views/private/Razorpay/Razorpay.index";
import { SiRazorpay } from "react-icons/si";
import { IoCalendar, IoSearch } from "react-icons/io5";
import {
  BsFileExcelFill,
  BsPeople,
  BsRecord,
  BsClipboardX,
  BsFillTelephoneFill
} from "react-icons/bs";
import { FaFileInvoice, FaRegMoneyBillAlt } from "react-icons/fa";
import DemoSessionBooking from "../views/private/DemoSessionBooking";
import CSVIndex from "../views/private/CSV/csv.index";
import Sessions from "../views/private/Sessions/Sessions";
import InstRecording from "../views/private/InstRecording";

import { UserRoleEnum } from "../enum/role.enum";
import SearchInst from "../views/private/SearchInst/SearchInst.index";

import { RiNotification2Fill, RiUserSearchFill } from "react-icons/ri";
import Announcement from "../views/private/Announcement/Announcement";
import EnrollmentInvoiceTab from "../views/private/EnrollmentInvoices/invoices.index";

import Renewals from "../views/private/Renewals/Renewals.index";

import UnpaidInstructorInvoices from "../views/private/unpaidInstructorInvoices/unpaidInstructorInvoices.index";
import Incentive from "../views/private/Incentive/Incentive";
import BlockedEnrollments from "../views/private/BlockedEnrollments/BlockedEnrollments";
import { BiBlock, BiPurchaseTag } from "react-icons/bi";
import AllUsers from "../views/private/RolesPage";
import CallRecordings from "../views/private/CallRecordings/CallRecording";
import Version from "../views/private/Version/Version";
import SalesIndex from "../views/private/Sales/Sales.index";



export const baseUrl = "/admin";

export const publicConfig: IRouterPublic[] = [
  {
    path: "/",
    element: Login,
  },
  {
    path: "/admin/login",
    element: Login,
  },
];

export const privateConfig: IRouterPrivate[] = [
  {
    path: "search",
    element: HomePage,
    show: true,
    name: "Search",
    icon: IoSearch,
    role: UserRoleEnum.SEARCH,
  },

  {
    path: "sessions",
    element: Sessions,
    show: true,
    name: "Sessions",
    icon: IoCalendar,
    role: UserRoleEnum.SESSIONS,
  },
  {
    path: "users",
    element: AllUsers,
    show: true,
    name: "Role",
    icon: BsPeople,
    role: UserRoleEnum.ROLE,
  },

  {
    path: "bulkSession",
    element: BulkSessionBooking,
    show: true,
    name: "Bulk Booking",
    icon: IoCalendar,
    role: UserRoleEnum.BULKBOOKING,
  },
  {
    path: "demoSession",
    element: DemoSessionBooking,
    show: true,
    name: "Demo Booking",
    icon: IoCalendar,
    role: UserRoleEnum.DEMOBOOKING,
  },
  {
    path: "razorpay",
    element: RazorPayIndex,
    show: true,
    name: "Payment Links",
    icon: SiRazorpay,
    role: UserRoleEnum.PAYMENTLINKS,
  },
  {
    path: "sales",
    element: SalesIndex,
    show: true,
    name: "E-commerce",
    icon: BiPurchaseTag,
    role: UserRoleEnum.PAYMENTLINKS,
  },
  {
    path: "csv",
    element: CSVIndex,
    show: true,
    name: "Excel Data",
    icon: BsFileExcelFill,
    // enum: userAllowed(),
    role: UserRoleEnum.EXCELDATA,
  },
  {
    path: "instRecording",
    element: InstRecording,
    show: true,
    name: "Recording",
    icon: BsRecord,
    // enum: userAllowed(),
    role: UserRoleEnum.RECORDING,
  },
  {
    path: "invoices",
    element: EnrollmentInvoiceTab,
    show: true,
    name: "Invoices",
    icon: FaFileInvoice,
    // enum: userAllowed(),
    role: UserRoleEnum.INVOICES,
  },
  {
    path: "search-inst",
    element: SearchInst,
    show: true,
    name: "Find Instructor",
    icon: RiUserSearchFill,
    role: UserRoleEnum.INSTRUCTOR_SEARCH,
  },
  {
    path: "announcements",
    element: Announcement,
    show: true,
    name: "Announcements",
    icon: RiNotification2Fill,
    role: UserRoleEnum.ANNOUNCEMENTS,
  },
  {
    path: "renewals",
    element: Renewals,
    show: true,
    name: "Renewals",
    icon: RiUserSearchFill,
    role: UserRoleEnum.RENEWALS,
  },

  {
    path: "search/student/detail/:id",
    element: Student,
    show: false,
    name: "Student",
  },

  {
    path: "search/course/detail/:id",
    element: CourseDetails,
    show: false,
    name: "CourseDetail",
  },
  {
    path: "search/instructor/detail/:id",
    element: Instructor,
    show: false,
    name: "Instructor",
  },
  {
    path: "search/log/detail",
    element: LogDetail,
    show: false,
    name: "LogDetail",
  },
  {
    path: "search/enrollment/detail/:id",
    element: Enrollment,
    show: false,
    name: "Enrollment",
  },
  {
    path: "incentive",
    element: Incentive,
    show: true,
    name: "Incentive",
    icon: FaRegMoneyBillAlt,
    role: UserRoleEnum.INCENTIVES,
  },
  {
    path: "unpaidInstructorInvoices",
    element: UnpaidInstructorInvoices,
    show: true,
    name: "Unpaid Invoices",
    icon: BsClipboardX,
    role: UserRoleEnum.UNPAID_INVOICES,
  },
  {
    path: "blocked-enrollment",
    element: BlockedEnrollments,
    show: true,
    name: "Blocked Enrollements",
    icon: BiBlock,
    role: UserRoleEnum.BLOCKED_ENROLLMENTS,
  },
  {
    path: "call-recording",
    element: CallRecordings,
    show: true,
    name: "Call Recording",
    icon: BsFillTelephoneFill,
    role: UserRoleEnum.CALL_RECORDING,
  },
  {
    path: "version",
    element: Version,
    show: true,
    name: "Version",
    icon: FaRegMoneyBillAlt,
    role: UserRoleEnum.VERSION,
  }
];

export const privateConfigWithSection: IRouterPrivateWithSection[] = [
  {
    sectionName: "Find",
    icon: IoSearch,
    options: [
      {
        path: "search",
        element: HomePage,
        show: true,
        name: "Search",
        icon: IoSearch,
        role: UserRoleEnum.SEARCH,
      },
      {
        path: "search-inst",
        element: SearchInst,
        show: true,
        name: "Find Instructor",
        icon: RiUserSearchFill,
        role: UserRoleEnum.INSTRUCTOR_SEARCH,
      },
    ]
  },
  {
    sectionName: "Sessions",
    icon: IoCalendar,
    options:[
      {
        path: "sessions",
        element: Sessions,
        show: true,
        name: "Sessions",
        icon: IoCalendar,
        role: UserRoleEnum.SESSIONS,
      },
      {
        path: "bulkSession",
        element: BulkSessionBooking,
        show: true,
        name: "Bulk Booking",
        icon: IoCalendar,
        role: UserRoleEnum.BULKBOOKING,
      },
      {
        path: "demoSession",
        element: DemoSessionBooking,
        show: true,
        name: "Demo Booking",
        icon: IoCalendar,
        role: UserRoleEnum.DEMOBOOKING,
      },
    ]
  },
  {
    sectionName: "Recording",
    icon: BsFillTelephoneFill,
    options:[
      {
        path: "call-recording",
        element: CallRecordings,
        show: true,
        name: "Call Recording",
        icon: BsFillTelephoneFill,
        role: UserRoleEnum.CALL_RECORDING,
      },
      {
        path: "instRecording",
        element: InstRecording,
        show: true,
        name: "Recording",
        icon: BsRecord,
        // enum: userAllowed(),
        role: UserRoleEnum.RECORDING,
      },
    ]
  },
  {
    sectionName:"Payments and Invoices",
    icon:SiRazorpay,
    options:[
      {
        path: "razorpay",
        element: RazorPayIndex,
        show: true,
        name: "Payment Links",
        icon: SiRazorpay,
        role: UserRoleEnum.PAYMENTLINKS,
      },
      {
        path: "sales",
        element: SalesIndex,
        show: true,
        name: "E-commerce",
        icon: BiPurchaseTag,
        role: UserRoleEnum.PAYMENTLINKS,
      },
      {
        path: "invoices",
        element: EnrollmentInvoiceTab,
        show: true,
        name: "Invoices",
        icon: FaFileInvoice,
        // enum: userAllowed(),
        role: UserRoleEnum.INVOICES,
      },
      {
        path: "incentive",
        element: Incentive,
        show: true,
        name: "Incentive",
        icon: FaRegMoneyBillAlt,
        role: UserRoleEnum.INCENTIVES,
      },
      {
        path: "unpaidInstructorInvoices",
        element: UnpaidInstructorInvoices,
        show: true,
        name: "Unpaid Invoices",
        icon: BsClipboardX,
        role: UserRoleEnum.UNPAID_INVOICES,
      },
      {
        path: "csv",
        element: CSVIndex,
        show: true,
        name: "Excel Data",
        icon: BsFileExcelFill,
        // enum: userAllowed(),
        role: UserRoleEnum.EXCELDATA,
      },
    ]
  },
  {
    sectionName:"Others",
    icon:BsPeople,
    options:[
      {
        path: "users",
        element: AllUsers,
        show: true,
        name: "Role",
        icon: BsPeople,
        role: UserRoleEnum.ROLE,
      },
      {
        path: "announcements",
        element: Announcement,
        show: true,
        name: "Announcements",
        icon: RiNotification2Fill,
        role: UserRoleEnum.ANNOUNCEMENTS,
      },
      {
        path: "renewals",
        element: Renewals,
        show: true,
        name: "Renewals",
        icon: RiUserSearchFill,
        role: UserRoleEnum.RENEWALS,
      },
      {
        path: "blocked-enrollment",
        element: BlockedEnrollments,
        show: true,
        name: "Blocked Enrollements",
        icon: BiBlock,
        role: UserRoleEnum.BLOCKED_ENROLLMENTS,
      },
      {
        path: "search/student/detail/:id",
        element: Student,
        show: false,
        name: "Student",
      },
      {
        path: "search/course/detail/:id",
        element: CourseDetails,
        show: false,
        name: "CourseDetail",
      },
      {
        path: "search/instructor/detail/:id",
        element: Instructor,
        show: false,
        name: "Instructor",
      },
      {
        path: "search/log/detail",
        element: LogDetail,
        show: false,
        name: "LogDetail",
      },
      {
        path: "search/enrollment/detail/:id",
        element: Enrollment,
        show: false,
        name: "Enrollment",
      },
      {
        path: "version",
        element: Version,
        show: true,
        name: "Version",
        icon: FaRegMoneyBillAlt,
        role: UserRoleEnum.VERSION,
      }
    ]
  }
];
