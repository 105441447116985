import { useEffect, useState } from 'react'
import { Button, Dropdown, Form, Modal, Table } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import CardBasic from '../../../../../components/Card/card.basic';
import { FontBold, NormalText } from '../../../../../components/Text/TableText.formats';
import Heading from '../../TableHeading/Heading';
import { AdminService } from '../../../../../services/admin.service';
import Select from 'react-select';
import CustomToggle from '../../../../../components/Menu/CustomMenu';
import { BiDotsVertical } from 'react-icons/bi';
import { IoPencilOutline, IoTrash } from 'react-icons/io5';
import SideModal from '../../../../../components/SideModal/SideModal';
import ConfirmationGlobalModal from '../../../../../components/Modals/Confirmation.global.modal';
import { getDealPipelineNameByDealPipelineId } from '../../../../../helpers/freshworks.helper';


interface IPayment {
  id: string

}

interface IDealPipeline {
  pipelineId: string,
  pipelineName: string,
  _id: string,
}
const DealsTable = ({ id }: IPayment) => {

  const [tableData, setTableData] = useState<any>();

  const [showStageUpdate, setShowStageUpdate] = useState(undefined);
  const [stage, setStage] = useState<any>();
  const [showAmountModal, setShowAmountModal] = useState<any>(undefined);
  const [editAmount, setEditAmount] = useState<any>();
  const [showConfirmationModal, setShowConfirmationModal] = useState<any>(undefined);
  const [deal_id, setDeal_id] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [dealPipeline, setDealPipeline] = useState<IDealPipeline>({
    pipelineId: '',
    pipelineName: '',
    _id: '',
  });



  const fetchFreshWorksDeals = async (id: any) => {
    await AdminService.getFreshworksDeals(id).then((res) => {
      if (res.status === 200) {
        setTableData(res.data.deals.deals); 
      }
    })
      .catch((error) => {
        toast.error('Unable to fetch deals');
        console.log(error);
      });
  }

  const updateFreshworksStage = async () => {
    if (!showStageUpdate) {
      toast.error('Please select a deal to update');
      return;
    }
    const payload = {
      pipelineId: dealPipeline.pipelineId,
      dealId: showStageUpdate,
      stage: stage.value,
      id: dealPipeline._id,
    }
    await AdminService.updateDealStage(payload).then((res) => {
      if (res.status === 200) {
        toast.success('Deal updated successfully');
        setShowStageUpdate(undefined);
      }
    })
    .catch((error) => {
      toast.error('Unable to fetch deals');
    });
    await fetchFreshWorksDeals(id);
  }

  const getDealByDealId = async (dealId: any,_id:any) => {
    setLoading(true);
    await AdminService.getDealByDealId(dealId).then((res) => {
      if (res.status === 200) {
        console.log(res.data.deal.deal.deal_pipeline_id);
        const dealPipelineName = getDealPipelineNameByDealPipelineId(res.data.deal.deal.deal_pipeline_id);
        console.log(dealPipelineName)
        setDealPipeline({
          pipelineName: dealPipelineName || '',
          pipelineId: res.data.deal.deal.deal_pipeline_id,
          _id: _id,
        })
        setLoading(false);
      }
      else {
        toast.error("Unable to get deal details");
        setLoading(false);
        setShowStageUpdate(undefined);
      }
    })
      .catch((error) => {
        setLoading(false);
        toast.error("Unable to get deal details");
        setShowStageUpdate(undefined);
        console.log(error);
      })
  }

  const updateDealStageHandler = (dealId: any, dealPipelineId?: any, _id?:any) => {
    setShowStageUpdate(dealId);
    if (!dealPipelineId) {
      getDealByDealId(dealId, _id);
    }
    else {
      console.log(dealPipelineId);
      const dealPipelineName = getDealPipelineNameByDealPipelineId(dealPipelineId);
      console.log(dealPipelineName)
      setDealPipeline({
        pipelineName: dealPipelineName || '',
        pipelineId: dealPipelineId,
        _id: _id,
      })
    }
  }

  const updateDealAmount = async ()=>{
    const data  = {
      dealId : showAmountModal,
      id: deal_id,
      amount: editAmount,
    }
    await AdminService.updateDealAmount(data).then((res)=>{
      if(res.status==200){
        toast.success("Deal amount updated");
      }
    })
    .catch((error)=>{
      console.log(error);
      toast.error("Deal not updated");
    })
    setShowAmountModal(undefined);
    await fetchFreshWorksDeals(id);
  }

  const updateDealAmountHandler =async (dealId: any, amount:any, _id?:any,) =>{
    setShowAmountModal(dealId);
    setEditAmount(amount)
    setDeal_id(_id);
  }

  const deleteDeal =async ()=>{
    const data = {
      dealId : showConfirmationModal,
      id: deal_id,
    }

    await AdminService.deleteDealByDealId(data).then((res)=>{
      if(res.status==200){
        toast.success("Deal deleted successfully");
      }
    })
    .catch((error)=>{
      console.log(error);
      toast.error("Deal not delete");
    })
    setShowConfirmationModal(undefined);
    setDeal_id(undefined);
    await fetchFreshWorksDeals(id);
  }

  const deleteDealHandler =async (dealId: any, _id?:any,) =>{
    setShowConfirmationModal(dealId);
    setDeal_id(_id);
  }

  


  useEffect(() => {
    fetchFreshWorksDeals(id);
  }, []);

  console.log(tableData);


  return (
    <>
      <Modal show={showStageUpdate} onHide={() => setShowStageUpdate(undefined)}>
        <Modal.Header closeButton>
          <Modal.Title>Update Deal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='alert alert-success'>
            Current Pipeline - {loading ? <em>Please Wait</em> : dealPipeline.pipelineName}
          </div>
          <div className='mb-3'>
            <Select
              options={[
                { label: 'Paid', value: 'Paid' },
                { label: 'Partial', value: 'Partial' },
                { label: 'Pending', value: 'Pending' }
              ]}
              onChange={setStage}
            />
          </div>
          <div>
            <Button onClick={()=>{updateFreshworksStage()}}>
              Update
            </Button>
          </div>

        </Modal.Body>
      </Modal>
      <CardBasic
        body={
          <>
            <Heading heading="Deals Details" />
            <div
              className="m-4"
              style={{ maxHeight: "500px", overflow: "scroll" }}
            >
              <Table striped borderless hover responsive size="sm">
                <thead>
                  <tr className="dhun-table-header text-secondary">
                    <th>#</th>
                    <th>Deals Id</th>
                    <th>Deals Name</th>
                    <th>Pipeline Name</th>
                    <th>Status</th>
                    <th>Amount</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {tableData && tableData?.dealsId?.length > 0
                    ? tableData?.dealsId?.map((data: any, index: any) => {
                      return (
                        <tr key={data?._id}>
                          <td>
                            <NormalText value={index + 1} />
                          </td>
                          <td>
                            <FontBold value={data.id} />
                          </td>
                          <td>
                            <FontBold value={data.name || "--"} />

                          </td>
                          <td>
                            <FontBold value={data.pipelineId ? getDealPipelineNameByDealPipelineId(data.pipelineId) : "--"} />

                          </td>
                          <td>
                            <FontBold value={data.status || "--"} />
                          </td>
                          <td>
                            <FontBold value={data.amount || "--"} />
                          </td>
                          <td>
                            <Dropdown>
                              <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                <BiDotsVertical />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  className="text-secondary fs-12 align-items-center d-flex fw-bold"
                                  onClick={() => updateDealStageHandler(data.id, data.pipelineId,  tableData?._id)}
                                >
                                  <IoPencilOutline className="text-primary me-1" /> Edit Status
                                </Dropdown.Item>
                                <Dropdown.Item
                                  className="text-secondary fs-12 align-items-center d-flex fw-bold"
                                  onClick={() => { updateDealAmountHandler(data.id, data.amount, tableData?._id) }}
                                >
                                  <IoPencilOutline className="text-primary me-1" /> Edit Amount
                                </Dropdown.Item>
                                <Dropdown.Item
                                  className="text-secondary fs-12 align-items-center d-flex fw-bold"
                                  onClick={() => {deleteDealHandler(data.id,  tableData?._id) }}
                                >
                                  <IoTrash className="text-danger me-1" /> Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      );
                    })
                    : "No Data Available"}
                </tbody>
              </Table>
            </div>
            <SideModal
              show={showAmountModal}
              handleClose={() => setShowAmountModal(false)}
              body={
                <div className="w-100  mt-1" style={{ height: "90vh" }}>
                  <Form.Group className="mt-2">
                    <Form.Label className="fw-bold text-secondary fs-12">
                      Amount
                    </Form.Label>
                    <Form.Control
                      type="number"
                      value={editAmount}
                      onChange={(e) => setEditAmount(e.target.value)}
                    />
                  </Form.Group>
                  <button
                    className="btn btn-primary btn-block mt-6"
                    onClick={() => {
                      updateDealAmount();
                    }}
                  >
                    Update
                  </button>
                </div>
              }
              title="Update Amount"
            />

            <ConfirmationGlobalModal
              show={showConfirmationModal}
              setShow={setShowConfirmationModal}
              onSuccess={() => {deleteDeal()}}
              alertBody={"Are you sure you want to delete this?"}
              body={<>This will delete the deal.</>}
            />
          </>
        }
      />
    </>
  );
}

export default DealsTable;
