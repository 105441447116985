import moment from "moment";
import { useEffect, useState } from "react"
import CardBasic from "../../../../../components/Card/card.basic"
import JsonViewer from "../../../../../components/JsonViewer/Json.viewer";
import SideModal from "../../../../../components/SideModal/SideModal";
import { AdminService } from "../../../../../services/admin.service";
import InvoiceService from "../../../../../services/invoice.service";
import StudentService from "../../../../../services/student.service"
import { FaPencilAlt } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import { ImCross } from "react-icons/im";
import toast from "react-hot-toast";

interface IStatsStudents {
    studentId: string
}

export default function StatsStudents(props: IStatsStudents) {

    const [stats, setStats] = useState<any>({});
    const [lastPayment, setLastPayment] = useState<any>([]);
    const [cronsData, setCronsData] = useState<any>();
    const [kylasIdData, setKylasIdData] = useState<any>();
    const [showCronsModal, setShowCronsModal] = useState<boolean>(false);
    const [editFreshWorksId, setEditFreshworksId] = useState(false);
    const [FreshWorksId, setFreshWorksId] = useState('--');

    const getStudentStats = async () => {
        await StudentService.getStudentStats(props.studentId).then(res => {
            if (res.status === 200) {
                setStats(res.data)
            }
        })
    }

    const getLastStudentPayment = async () => {
        await InvoiceService.getLastStudentPayment(props.studentId).then(res => {
            if (res.status === 200) {
                setLastPayment(res.data.data);
            }
        })
    }

    const getAllCrons = async () => {
        await AdminService.getAllCrons(props.studentId).then((res) => {
            if (res.status === 200) {
                setCronsData(res.data);
            }
        })
    }

    const getKyasId = async () => {
        await AdminService.getKylasId(props.studentId).then((res) => {
            if (res.status === 200) {
                setKylasIdData(res.data.data)
            }
        })
    }

    const handleChangeFreshWorksId = async () => {
        const freshworksId = FreshWorksId;
        await AdminService.manualUpdateFreshWorksId(props.studentId, FreshWorksId).then((res) => {
            if (res.status == 200) {
                toast.success('FreshWorksId updated');
                setEditFreshworksId(false);
            }
            else {
                setFreshWorksId(freshworksId);
            }
        })
            .catch((error) => {
                toast.error('FreshWorksId not changed');
                console.log(error);
                setFreshWorksId(freshworksId);
                setEditFreshworksId(false);
            })

        getFreshWorksIdByDhunguruId();
    }

    const getFreshWorksIdByDhunguruId = async () => {
        await AdminService.getFreshWorksLeadIdByDhunguruId(props.studentId).then((res) => {
            if (res.status == 200) {
                console.log(res.data);
                setFreshWorksId(res.data.id);
            }
        })
            .catch((error) => {
                toast.error("Something went wrong");
                console.log(error);
            })
    }


    useEffect(() => {
        getFreshWorksIdByDhunguruId();
    }, []);


    useEffect(() => {
        getStudentStats();
        getLastStudentPayment()
        getAllCrons();
        getKyasId();
    }, [])

    return (
        <>
            <CardBasic
                body={
                    <>
                        <div>
                            <div className="fw-bold fs-grey fs-12">
                                Next Session On: <span className="text-black">{stats.upcoming_session ? moment(stats.upcoming_session.date_time).format("Do MMM YY hh:mm a") : "No Session Scheduled"}</span>
                            </div>
                            {/* <div className="fw-bold fs-grey fs-12">
                                Kylas Id: <span className="text-black">{kylasIdData && kylasIdData[0]?.kylasId ? kylasIdData[0]?.kylasId : "NA"}</span>
                            </div> */}
                            <div className="fw-bold fs-grey fs-12">
                                Cron(s) Active: <span className="fst-italic text-primary fs-12 fs-underline cursor-pointer" onClick={() => setShowCronsModal(true)}>{cronsData && cronsData.data && cronsData.data.filter((cron: any) => cron.completed === false).length}</span>
                            </div>
                            <div className="fw-bold fs-grey fs-12">
                                Last Purchase: <span className="text-black">{
                                    lastPayment && lastPayment.length > 0 ?
                                        <>INR {lastPayment[0].payment_details[lastPayment[0].payment_details.length - 1].total_price} on <span> {moment(lastPayment[0].payment_details[lastPayment[0].payment_details.length - 1].payment_date).format("DD MMM YY hh:mm a")} </span></>
                                        : <>
                                            ---
                                        </>
                                }</span>
                            </div>
                            <div className="fw-bold fs-grey fs-12 d-flex flex-row">
                                FreshWorksId: <span className="fst-italic text-black fs-12 fs-underline cursor-pointer" >{!editFreshWorksId ? " " + FreshWorksId : <input value={FreshWorksId} onChange={(e) => { setFreshWorksId(e.target.value) }}></input>}</span>
                                {!editFreshWorksId && <FaPencilAlt
                                    onClick={() => { setEditFreshworksId(true) }}
                                    style={{ fontSize: '1.0rem', paddingLeft: '5px' }}
                                />}
                                {editFreshWorksId && <TiTick
                                    onClick={() => { handleChangeFreshWorksId() }}
                                    style={{ fontSize: '1.1rem', paddingLeft: '5px', color: 'green' }}
                                />}
                                {editFreshWorksId && <ImCross
                                    onClick={() => { setEditFreshworksId(false) }}
                                    style={{ fontSize: '1.1rem', paddingLeft: '5px', color: 'red' }}
                                />}
                            </div>

                        </div>
                    </>
                }
            />
            <JsonViewer
                show={showCronsModal}
                setShow={setShowCronsModal}
                jsonData={cronsData && cronsData.data && cronsData.data.filter((cron: any) => cron.completed === false)}

            />
        </>
    )
}