
export enum NewStudentStagesEnum {
    PaymentFolowUp = "Payment follow up",
    PaymentLinkShared = "Payment Link Shared",
    PartialPaymentDone = "Partial Payment Done",
    FullPaymentDone = "Full Payment Done",
    ClosedLost = "Closed Lost",
}

export enum RenewalStagesEnum {
    Open = "Open",
    NotContacted = "Not Contacted",
    FollowUp = "Followup",
    OffersShared = "Offers Shared",
    PaymentLinkShared = "Payment Link Shared",
    PartialPayment = "Partial Payment",
    RenewalCompleted = "Renewal Completed",
    ClosedLost = "Closed Lost",
}

export enum RefferalStagesEnum {
    Open = "Open",
    NotContacted = "Not Contacted",
    FollowUp = "Followup",
    DemoRequested = "Demo Requested",
    DemoScheduled = "Demo Scheduled",
    DemoReScheduled = "Demo Rescheduled",
    DemoCompleted = "Demo Completed",
    SecondDemoRequested = "Second Demo Requested",
    SecondDemoScheduled = "Second Demo Scheduled",
    SecondDemoReScheduled = "Second Demo Rescheduled",
    SecondDemoCompleted = "Second Demo Completed",
    PaymentFollowUp = "Payment FollowUp",
    PartiallyPaid = "Partially Paid",
    FullPaymentDone = "Full Payment Done",
    ClosedLost = "Closed Lost",
}

export enum AdditionalSubjectStagesEnum {
    Open = "Open",
    FollowUp = "Followup",
    DemoRequested = "Demo Requested",
    DemoScheduled = "Demo Scheduled",
    DemoReScheduled = "Demo Rescheduled",
    DemoCompleted = "Demo Completed",
    SecondDemoRequested = "Second Demo Requested",
    SecondDemoScheduled = "Second Demo Scheduled",
    SecondDemoReScheduled = "Second Demo Rescheduled",
    SecondDemoCompeleted = "Second Demo Compeleted",
    PaymentFollowUp = "Payment FollowUp",
    PartiallyPaid = "Partially Paid",
    FullPaymentDone = "Full Payment Done",
    ClosedLost = "Closed Lost",
}

export const dealsType = {
    NS: 'New Student - NS',
    RW: 'Renewal -RW',
    RF: 'Referral - RF',
    AS: 'Additional Subject - AS',
}

export const fwDealPiplines = {
    RW : 402000166523,
    NS : 402000159454,
    AS : 402000168910,
    RF : 402000181075,
}


export function getStageOptionsByPipeline(pipelineName: any) {
    let options;
    if (pipelineName == dealsType.NS) {
        options = Object.entries(NewStudentStagesEnum).map(([key, value]): any => ({
            value: value,
            label: value,
        }))
    }
    else if (pipelineName == dealsType.AS) {
        options = Object.entries(AdditionalSubjectStagesEnum).map(([key, value]): any => ({
            value: value,
            label: value,
        }))
    }
    else if (pipelineName == dealsType.RW) {
        options = Object.entries(RenewalStagesEnum).map(([key, value]): any => ({
            value: value,
            label: value,
        }))
    }
    else if (pipelineName == dealsType.RF) {
        options = Object.entries(RefferalStagesEnum).map(([key, value]): any => ({
            value: value,
            label: value,
        }))
    }

    return options;
}

export function getDealPipelineNameByDealPipelineId (dealPipelineId:any){
    if(String(dealPipelineId)==String(fwDealPiplines.AS)){
        return dealsType.AS;
    }
    else if(String(dealPipelineId)==String(fwDealPiplines.NS)){
        return dealsType.NS;
    }
    else if(String(dealPipelineId)==String(fwDealPiplines.RF)){
        return dealsType.RF;
    }
    else if(String(dealPipelineId)==String(fwDealPiplines.RW)){
        return dealsType.RW;
    }
}