import { Button, Card, CardContent, Grid } from '@mui/material'
import React from 'react'

const btn = {
    backgroundColor: '#2ecc71',
    fontSize: '9px',
    textTransform: 'none',
    color: 'white',
    padding: '2px',
    margin: '2px',
    "&:hover": {
        backgroundColor: '#2ecc71',
        color: 'white',
    }
}

const btn2 = {
    backgroundColor: '#e74c3c',
    fontSize: '9px',
    textTransform: 'none',
    color: 'white',
    padding: '2px',
    margin: '0',
    "&:hover": {
        backgroundColor: '#e74c3c',
        color: 'white',
    }
}

const ptagbold = {
    padding: '0',
    margin: '0',
    fontWeight: 'bold',
    fontSize: '12px'
}


const ptag = {
    padding: '0',
    margin: '0',
    fontSize: '12px'
}

function Requests(props: any) {
    const Requests = props.requests
    return (
        <>
            {
                Requests.length !== 0 ? <>
                    <h3>Requests</h3>


                </> : "No Requests data to show"
            }

        </>
    )
}

export default Requests